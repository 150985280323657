import { GroupAssociation } from './components/discovery-component/pipes/group-association.pipe';
import { DiscoveryRouterComponent } from './components/discovery-router/discovery-router.component';
import { FutureIntegrateComponent } from './components/future-integrate/future-integrate.component';
import { StoriesIntegrationComponent } from './components/stories-integration/stories-integration.component';
import { CampaignGuestsComponent } from './components/campaign-guests/campaign-guests.component';
import { StoriesMessageComponent } from './components/stories-message/stories-message.component';
import { MobilePageComponent } from './components/mobile-page/mobile-page.component';
import { FriendLandingEffects } from './store/friend-landing/effects';
import { FriendLandingComponent } from './components/friend-landing/friend-landing.component';
import { StoryExplorerComponent } from './components/story-explorer/story-explorer.component';
import { FutureMessageComponent } from './components/future-message/future-message.component';
import { PoststayProcessEffects } from './store/poststay-process/effects';
import { PoststayProcessComponent } from './components/poststay-process/poststay-process.component';
import { InvitationEmailComponent } from './components/invitation-email/invitation-email.component';
import { BannerPrearrivalEmailEffects } from './store/banner-prearrival-email/effects';
import { BannerPrearrivalEmailComponent } from './components/banner-prearrival-email/banner-prearrival-email.component';
import { BannerEmailEffects } from './store/banner-email/effects';
import { BannerEmailComponent } from './components/banner-email/banner-email.component';
import { FollowupEmailEffects } from './store/followup-email/effects';
import { FollowupEmailComponent } from './components/followup-email/followup-email.component';
import { CertificateEmailComponent } from './components/certificate-email/certificate-email.component';
import { JourneysApiService } from './services/journeys-api.service';
import { ConfirmationExperienceEffects } from './store/confirmation-experience/effects';
import { CertificateEmailEffects } from './store/certificate-email/effects';
import {
    ConfirmationExperienceComponent
} from './components/confirmation-experience/confirmation-experience.component';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CommonModule } from '@angular/common';
import { makeChildProviders } from '@uirouter/angular';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { BookNowComponent } from './components/book-now/book-now.component';
import { BookingBarComponent } from './components/booking-bar/booking-bar.component';
import { PlannerFlowComponent } from './components/planner-flow/planner-flow.component';
import { DiscoveryComponentComponent } from './components/discovery-component/discovery-component.component';
import { IntegrationComponent } from './components/discovery-component/integration';
import { StylingComponent } from './components/discovery-component/styling';
import { TouchpointEntryComponent } from './components/touchpoint-entry/touchpoint-entry.component';
import { TouchpointHeaderComponent } from './components/touchpoint-header/touchpoint-header.component';
import { BookNowHeaderComponent } from './components/book-now/book-now-header/book-now-header.component';
import { SidebarTabsModule } from './components/sidebar-tabs/sidebar-tabs.module';
import { reducers } from './store/touchpoints.reducer';
import { BooknowEffects } from './store/book-now/effects';
import { BookingbarEffects } from './store/booking-bar/effects';
import { PlannerflowEffects } from './store/planner-flow/effects';
import { DiscoveryApiService } from './services/discovery-api.service';
import { DiscoveryComponentEffects, DiscoveryInjectableComponentEffects } from './store';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonDeclarationsModule } from '../../modules/common-declarations/common-declarations.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
    ReEngagementExperienceComponent
} from './components/re-engagement-experience/re-engagement-experience.component';
import { ReEngagementEmailComponent } from './components/re-engagement-email/re-engagement-email.component';
import { ReEngagementEmailGlobalComponent } from './components/re-engagement-email-global/re-engagement-email-global.component';
import { PermissionsModule } from '../permissions/permissions.module';
import { PlannerFlowGlobalComponent } from './components/planner-flow-global/planner-flow-global.component';
import { ConfirmationDisplayTypePipe } from './pipes/confirmation-display-type.pipe';
import { InvitationEmailEffects } from './store/invitation-email';
import { StoryExplorerEffects } from './store/story-explorer';
import { CampaignGuestsEffects } from './store/campaign-guests';
import { OrderModule } from 'ngx-order-pipe';
import { CampaignGoalsComponent } from './components/campaign-goals/campaign-goals.component';
import {
    DiscoveryBarComponent
} from 'account-hybrid/common/components/touchpoints/components/discovery-bar/discovery-bar.component';
import {
    PropertiesAssociationsComponent
} from 'account-hybrid/common/components/touchpoints/components/discovery-component/properties-associations/properties-associations.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {
    PropertySettingsEffects
} from 'account-hybrid/common/components/touchpoints/store/property-settings/property-settings.effects';
import { PropertyUserDataService } from 'account-hybrid/features/mission-control/services/property-user-data.service';
import {
    MissionControlSettingsDetailsService
} from 'account-hybrid/features/mission-control/services/mission-control-settings-details.service';
import { EntityDefinitionService, EntityMetadataMap, HttpUrlGenerator } from '@ngrx/data';
import { PropertyUserDto } from 'account-hybrid/features/mission-control/models';
import { HttpClient } from '@angular/common/http';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';

const entityMetadata: EntityMetadataMap = {
    PropertyUser: {
        selectId: (user: PropertyUserDto) => user.Id
    }
};


const ROUTER_STATES = makeChildProviders({
    states: [
        {
            name: 'discovery.journeys.booknow',
            url: '/{componentGroupUuid}/booknow?{version}',
            views: { booknow: { component: BookNowComponent } }
        },
        {
            name: 'discovery.journeys.bookingbar',
            url: '/{componentGroupUuid}/bookingbar?{version}',
            views: { bookingbar: { component: BookingBarComponent } }
        },
        {
            name: 'discovery.journeys.discoverybar',
            url: '/{componentGroupUuid}/discoverybar?{version}',
            views: { discoverybar: { component: DiscoveryBarComponent } }
        },
        {
            name: 'discovery.journeys.plannerflow',
            url: '/plannerflow?{version}',
            views: { plannerflow: { component: PlannerFlowComponent } }
        },
        {
            name: 'discovery.journeys.discoveryRouter',
            url: '/discoveryRouter/{componentGroupUuid}?{version}',
            views: { discoveryRouter: { component: DiscoveryRouterComponent } }
        },
        {
            name: 'discovery.journeys.plannerflowglobal',
            url: '/{componentGroupUuid}/plannerflowglobal?{version}',
            views: { plannerflowglobal: { component: PlannerFlowGlobalComponent } }
        },
        {
            name: 'discovery.journeys.siteintegration',
            url: '/{componentGroupUuid}/siteintegration?{version}',
            views: { siteintegration: { component: DiscoveryComponentComponent } }
        },
        {
            name: 'discovery.journeys.reengagementemail',
            url: '/reengagementemail/{email}',
            views: { reengagementemail: { component: ReEngagementEmailComponent } }
        },
        {
            name: 'discovery.journeys.reengagementemailglobal',
            url: '/{componentGroupUuid}/reengagementemailglobal}',
            views: { reengagementemailglobal: { component: ReEngagementEmailGlobalComponent } }
        },
        {
            name: 'discovery.journeys.reengagementexperience',
            url: '/{componentGroupUuid}/reengagementexperience/{experience}',
            views: { reengagementexperience: { component: ReEngagementExperienceComponent } }
        },
        {
            name: 'advocacy-new.journeys.confirmationexperience',
            url: '/{componentGroupUuid}/confirmationexperience/{propertyId}',
            views: { confirmationexperience: { component: ConfirmationExperienceComponent } }
        },
        {
            name: 'advocacy-new.journeys.certificateemail',
            url: '/{componentGroupUuid}/certificateemail/{isReminder}',
            views: { certificateemail: { component: CertificateEmailComponent } }
        },
        {
            name: 'advocacy-new.journeys.followupemail',
            url: '/{componentGroupUuid}/followupemail/{propertyId}',
            views: { followupemail: { component: FollowupEmailComponent } }
        },
        {
            name: 'advocacy-new.journeys.bannerEmail',
            url: '/{componentGroupUuid}/bannerEmail/{propertyId}',
            views: { bannerEmail: { component: BannerEmailComponent } }
        },
        {
            name: 'advocacy-new.journeys.bannerPrearrivalEmail',
            url: '/{componentGroupUuid}/bannerPrearrivalEmail/{propertyId}',
            views: { bannerPrearrivalEmail: { component: BannerPrearrivalEmailComponent } }
        },
        {
            name: 'advocacy-new.journeys.invitationEmail',
            url: '/{componentGroupUuid}/invitationEmail/{propertyId}',
            views: { invitationEmail: { component: InvitationEmailComponent } }
        },
        {
            name: 'advocacy-new.journeys.poststayProcess',
            url: '/{componentGroupUuid}/poststayProcess/{propertyId}',
            views: { poststayProcess: { component: PoststayProcessComponent } }
        },
        {
            name: 'advocacy-new.journeys.storyExplorer',
            url: '/{componentGroupUuid}/storyExplorer/{propertyId}',
            views: { storyExplorer: { component: StoryExplorerComponent } }
        },
        {
            name: 'advocacy-new.journeys.friendLanding',
            url: '/{componentGroupUuid}/friendLanding/{propertyId}',
            views: { friendLanding: { component: FriendLandingComponent } }
        },
        {
            name: 'advocacy-new.journeys.campaignGuests',
            url: '/{componentGroupUuid}/campaignGuests/{propertyId}',
            views: { campaignGuests: { component: CampaignGuestsComponent } }
        }
    ]
});

@NgModule({
    declarations: [
        TouchpointEntryComponent,
        TouchpointHeaderComponent,
        BookingBarComponent,
        BookNowComponent,
        BookNowHeaderComponent,
        DiscoveryBarComponent,
        PlannerFlowComponent,
        DiscoveryComponentComponent,
        IntegrationComponent,
        StylingComponent,
        ReEngagementEmailComponent,
        ReEngagementEmailGlobalComponent,
        ReEngagementExperienceComponent,
        PlannerFlowGlobalComponent,
        PropertiesAssociationsComponent,
        DiscoveryRouterComponent,
        ConfirmationExperienceComponent,
        ConfirmationDisplayTypePipe,
        GroupAssociation,
        CertificateEmailComponent,
        FollowupEmailComponent,
        BannerEmailComponent,
        BannerPrearrivalEmailComponent,
        InvitationEmailComponent,
        PoststayProcessComponent,
        StoryExplorerComponent,
        FriendLandingComponent,
        MobilePageComponent,
        StoriesMessageComponent,
        StoriesIntegrationComponent,
        FutureMessageComponent,
        FutureIntegrateComponent,
        CampaignGuestsComponent,
        CampaignGoalsComponent,
        PropertiesAssociationsComponent
    ],
    imports: [
        UIRouterUpgradeModule.forChild({}),
        FormsModule,
        CommonModule,
        PermissionsModule,
        AccountSharedModule,
        MatTabsModule,
        MatIconModule,
        MatSlideToggleModule,
        SidebarTabsModule,
        OrderModule,
        NgbTooltipModule,
        CommonDeclarationsModule,
        StoreModule.forFeature('touchpoints', reducers),
        EffectsModule.forFeature([
            BooknowEffects,
            BookingbarEffects,
            PlannerflowEffects,
            DiscoveryComponentEffects,
            DiscoveryInjectableComponentEffects,
            PropertySettingsEffects,
            ConfirmationExperienceEffects,
            CertificateEmailEffects,
            FollowupEmailEffects,
            BannerEmailEffects,
            BannerPrearrivalEmailEffects,
            InvitationEmailEffects,
            PoststayProcessEffects,
            StoryExplorerEffects,
            FriendLandingEffects,
            CampaignGuestsEffects,
            PropertySettingsEffects
        ]),
        DragDropModule,
        MatDialogModule
    ],
    exports: [
        TouchpointEntryComponent,
        TouchpointHeaderComponent
    ],
    providers: [
        DiscoveryApiService,
        JourneysApiService,
        MissionControlSettingsDetailsService,
        PropertyUserDataService
    ]
})

export class TouchpointsModule {
    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
                eds: EntityDefinitionService,
                hug: HttpUrlGenerator) {
        eds.registerMetadataMap(entityMetadata);
        const propertyUuid = spacesService.getPropertyUuid();
        const propertyUserUrl = `${environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/users`;
        hug.registerHttpResourceUrls({
            PropertyUser: {
                entityResourceUrl: propertyUserUrl,
                collectionResourceUrl: propertyUserUrl
            }
        });
    }

    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: TouchpointsModule,
            providers: [
                ...ROUTER_STATES
            ]
        };
    }
}


