<div [ftStickySpacer]="totalChanges > 0 || hasChanges || hasDraftChanges"
     class="ft2-bg-striped py-5 mb-5 ft-slide-down"
     *ngIf="totalChanges > 0 || hasChanges || hasDraftChanges || alwaysVisible">
    <div class="ft2-container">
        <div class="row">
            <div class="col col-md-8 col-sm-12">
                <ng-container *ngIf="title">
                    <h2 class="h5 bold-text" *ngIf="titleVisible">{{ title }}</h2>
                </ng-container>
                <ng-container *ngIf="!title">
                    <h2 class="h5 bold-text" *ngIf="titleVisible">Confirm your changes.</h2>
                </ng-container>
                <p>
                    <ng-container *ngIf="message">
                        {{ message }}
                    </ng-container>
                    <ng-container *ngIf="!message">
                        <ng-container *ngIf="showCounter; else singleChange">
                            <ng-container *ngIf="totalChanges > 1; else singleChange">
                                You have {{ totalChanges }} changes pending.
                            </ng-container>
                        </ng-container>
                        <ng-template #singleChange>
                            You have pending changes.
                        </ng-template>
                        If everything looks good, save &amp; publish to apply
                        all changes.
                    </ng-container>
                </p>
                <ft-validation-checklist *ngIf="validationRules?.length"
                                         [(isValid)]="canCommit"
                                         (isValidChange)="onIsValidChange()"
                                         [rules]="validationRules">
                </ft-validation-checklist>
            </div>
            <div class="col-auto col-md-4 col-sm-12 h-end" [ngClass]="{ 'f-md-column f-sm-row': hasDraftChanges }">
                <ft-confirm-modal *ngIf="cancelVisible"
                                  [title]="'Are you sure you want to leave?'"
                                  [description]="'You have pending changes that haven’t been saved as a draft or published yet. If you leave this page, you will lose your changes.'"
                                  (confirm)="cancel.next(true)"
                                  [disabled]="!cancelConfirmation || !totalChanges"
                                  cancelLabel="Return to editing"
                                  confirmLabel="Continue to leave">
                    <button class="ft2-btn-shade -large mr-2" >
                        Cancel all
                    </button>
                </ft-confirm-modal>
                <button *ngIf="draftSaveVisible || hasDraftChanges" class="ft2-btn-shade -large mr-2"
                        [disabled]="(canSave$ | async)===false" (click)="draftCommit.next(true)">
                    {{ draftSaveButtonLabel }}
                </button>
                <button *ngIf="publishVisible" class="ft2-btn-primary -large" [disabled]="(canSave$ | async)===false"
                        (click)="commit.next(true)">
                    Save &amp; publish
                </button>
            </div>
        </div>
    </div>

    <div class="ft2-bg-striped ft-pending-changes_loading-view" *ngIf="loading$ | async">
        <span class="ft2-spinner -large"></span>
    </div>
</div>
