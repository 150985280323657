import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import * as fromTouchpoints from 'account-hybrid/common/components/touchpoints/store';
import { Observable } from 'rxjs';
import { DiscoveryComponentSettings, DiscoveryComponentReengagementEmailViewModel } from 'account-hybrid/common/components/touchpoints/models';
import { LogoLayoutType } from 'account-hybrid/common/components/touchpoints/enums';

@Component({
    selector: 'ft-re-engagement-email-global',
    templateUrl: './re-engagement-email-global.component.html'
})
export class ReEngagementEmailGlobalComponent implements OnInit {
    componentGroupUuid: string;
    reengagementEmail$: Observable<DiscoveryComponentReengagementEmailViewModel>;
    commitSuccess$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    error$: Observable<any>;
    logoLayoutTypeOptions = [
        { label: `Use property's logo`, value: LogoLayoutType.PropertyLogoOnly },
        { label: `Use brand's logo`, value: LogoLayoutType.CompanyLogoOnly }
    ];

    constructor(
      private store$: Store<any>,
      private transition: Transition
    ) {
    }

    ngOnInit(): void {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;
        this.reengagementEmail$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentReengagementEmail));
        this.isLoading$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsIsLoading));
        this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsCommitSuccess));
        this.error$ = this.store$.pipe(select(fromTouchpoints.selectDiscoveryComponentSettingsLoadingError));
    }

    commit(settings: Partial<DiscoveryComponentSettings>) {
        this.store$.dispatch(fromTouchpoints.commitDiscoveryComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings
        }));
    }
}
